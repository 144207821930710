import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { toast } from "react-toastify";
import { HighlightOff, CheckCircle, ArrowBack } from "@mui/icons-material";

import useRestaurants from "../../../models/restaurants/useRestaurants";
import useStories from "../../../models/stories/useStories";
import useAuth from "../../../models/auth/useAuth";
import { gaEvent } from "../../../utils/gaHelper";
import IgModal from "./Modal/IgModal";
import useUi from "../../../models/ui/useUi";
import useCampaigns from "../../../models/campaigns/useCampaigns";

import "./styles.scss";

const USForm = () => {
  const navigate = useNavigate();
  const { restaurant } = useRestaurants();
  const { campaign } = useCampaigns();
  const { createStory } = useStories();
  const inputRef = useRef();
  const [image, setImage] = useState();
  const [showModal, setShowModal] = useState(false);
  const { showLoader, hideLoader } = useUi();
  const [validationError, setValidationError] = useState(null);
  const [igAccount, setIgAccount] = useState();

  const countCampaigns = useMemo(() => {
    if (!restaurant.id) return 0;
    return (
      restaurant.brand_campaigns.length + restaurant.account_campaigns.length
    );
  }, [restaurant]);

  const getErrorMessage = (key) => {
    switch (key) {
      case "is_story":
        return "es una captura de pantalla de una story";
      case "has_restaurant_mention":
        return `se menciona a @${restaurant.ig_account}`;
      case "has_campaign_mention":
        return `se menciona a @${campaign.ig_account}`;
      case "has_campaign_bottle":
        return `se muestra la botella de ${campaign.bottle_requirement}`;
      case "has_campaign_glass":
        return `se muestra la copa de ${campaign.glass_requirement}`;
      case "has_food":
        return `se muestra comida`;
      default:
        return "error desconocido";
    }
  };

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  const handleSubmit = async () => {
    if (igAccount) handleCreateStory(igAccount);
    else setShowModal(true);
  };

  const handleCreateStory = async (igAccount) => {
    showLoader({
      loaderTitle: "Validando story",
      loaderText: "Este proceso puede tardar unos segundos",
    });
    setIgAccount(igAccount);
    try {
      const fd = new FormData();
      fd.append("ig_account", igAccount);
      fd.append("restaurant", restaurant.id);
      fd.append("image", image);
      if (campaign.brand) {
        fd.append("brand_campaign", campaign.id);
      }
      if (campaign.account) {
        fd.append("account_campaign", campaign.id);
      }
      const response = await createStory(fd);
      navigate(`../story-qr/${response.id}`);
    } catch (e) {
      const response = await e.json();
      if (response.error === "USER_IS_ALREADY_PARTICIPATING") {
        navigate("../already-participating", {
          state: { participationDate: response.date },
        });
      } else if (response.error === "CANT_FIND_IG_ACCOUNT") {
        //@TODO IMPLEMENTAR ESTO CORRECTAMENTE
        toast.error("Error al validar cuenta Instagram");
        navigate(`/verify-instagram`, {
          state: { restaurant: restaurant.slug },
        });
      } else if (response.error === "VALIDATION_ERROR") {
        toast.error("No hemos podido validar la story, revisa los errores.");
        gaEvent({
          name: "us_ia_rejection",
          params: { validation_error: response.validation },
        });
        setValidationError(response.validation);
      } else {
        toast.error(
          "Ups, ha ocurrido un error. Por favor vuelve a intentarlo."
        );
      }
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (image) {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [image]);

  useEffect(() => {
    gaEvent({ name: "us_form" });
  }, []);

  const steps = useMemo(() => {
    const requirements = [
      <>
        Haz una story en Instagram mencionando a{" "}
        <span>@{restaurant.ig_account}</span>
        {campaign && campaign.ig_account && (
          <>
            {" y a "}
            <span>@{campaign.ig_account}</span>
          </>
        )}
      </>,
    ];

    if (restaurant.has_follow_validation) {
      requirements.push(
        <>
          Sigue a<span> @{restaurant.ig_account}</span> en Instagram
        </>
      );
    }

    if (
      campaign &&
      (campaign.bottle_requirement || campaign.glass_requirement)
    ) {
      const bottleText = campaign.bottle_requirement && (
        <>
          <span>la botella de {campaign.bottle_requirement}</span>
        </>
      );
      const glassText = campaign.glass_requirement && (
        <>
          <span>la copa de {campaign.glass_requirement}</span>
        </>
      );

      const combinedText = (
        <>
          En la story tiene que aparecer{" "}
          {[bottleText, glassText]
            .filter(Boolean)
            .reduce((prev, curr) => [prev, " y ", curr])}
        </>
      );
      requirements.push(combinedText);
    }

    if (!campaign.brand && restaurant.has_food_validation) {
      requirements.push(
        <>
          La story debe mostrar nuestra comida,{" "}
          <span>¡enseña lo rica que está!</span>
        </>
      );
    }

    requirements.push(
      <>
        Una vez publicada,{" "}
        <span>
          haz una captura de pantalla de la story y súbela a continuación:
        </span>
      </>
    );

    return requirements;
  }, [restaurant, campaign]);

  return (
    <>
      <div className="USForm">
        <div className="USForm__main">
          {countCampaigns > 1 && (
            <div className="USForm__back">
              <Link to="../../">
                <div>Volver a premios</div>
              </Link>
            </div>
          )}
          <div className="USForm__title title">
            Consigue tu premio:{" "}
            <span>{campaign.influencer_prize.toLowerCase()} gratis</span>
          </div>
          {restaurant.participation_requirement && (
            <>
              <div className="USForm__subtitle subtitle">Requisitos</div>
              <div className="USForm__requirement">
                {restaurant.participation_requirement}
              </div>
            </>
          )}
          <div className="USForm__subtitle subtitle">Pasos</div>
          {steps.map((text, index) => (
            <div className="USForm__step" key={index}>
              <div className="USForm__step__number">{index + 1}</div>
              <div className="USForm__step__text">{text}</div>
            </div>
          ))}
        </div>
        {image && (
          <div className="USForm__image-preview">
            {validationError && (
              <div className="USForm__validation">
                <div className="USForm__validation__title">
                  Errores de validación.
                </div>
                <div className="USForm__validation__text">
                  Por favor, vuelve a subir la story corrigiendo estos errores:
                </div>
                {Object.entries(validationError).map(([key, value]) => (
                  <div className="USForm__validation__item" key={key}>
                    <div>
                      {value ? (
                        <CheckCircle color="success" />
                      ) : (
                        <HighlightOff color="error" />
                      )}
                    </div>
                    <div>
                      {!value
                        ? `NO ${getErrorMessage(key)}`
                        : `Sí ${getErrorMessage(key)}`}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <img src={URL.createObjectURL(image)} />
          </div>
        )}
        <div>
          <button className="USForm__file button" onClick={handleClickUpload}>
            Subir captura desde tu galería
            <DriveFolderUploadOutlinedIcon />
          </button>
          <input
            ref={inputRef}
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => {
              setImage(e.target.files[0]);
              setValidationError(null);
            }}
          />
          {!!image && !validationError && (
            <button
              className={classNames("button", {
                disabled: !image || validationError,
              })}
              onClick={handleSubmit}
            >
              Enviar
            </button>
          )}
          <div className="USForm__terms">
            Continuando, aceptas los{" "}
            <a href="https://www.wubalu.com/terms" target="_blank">
              términos y condiciones
            </a>
          </div>
        </div>
      </div>
      {showModal && (
        <IgModal
          slug={restaurant.slug}
          onClose={() => setShowModal(false)}
          handleCreateStory={handleCreateStory}
        />
      )}
    </>
  );
};

export default USForm;
