import { useEffect, useState } from "react";

import AutocompleteInput from "../../../components/Autocomplete";
import useRestaurants from "../../../models/restaurants/useRestaurants";
import thanks from "../../../assets/images/thanks.svg";
import ok from "../../../assets/images/ok.svg";
import { gaEvent } from "../../../utils/gaHelper";

import "./styles.scss";
import { useParams } from "react-router-dom";
import useStories from "../../../models/stories/useStories";
import useReferrals from "../../../models/referrals/useReferrals";

const USThanks = () => {
  const { storyId } = useParams();
  const { getRestaurantParticipants, restaurant } = useRestaurants();
  const { createReferral } = useReferrals();
  const { fetchStory, story } = useStories();
  const [participants, setParticipants] = useState([]);
  const [referral, setReferral] = useState();


  const handleCreateReferral = async () => {
    await createReferral({
      referrer_ig_account: referral,
      referred_ig_account: story.ig_account,
      story_id: storyId,
    });
    fetchStory(storyId);
  }

  useEffect(() => {
    fetchStory(storyId);
  }, [storyId]);

  useEffect(() => {
    (restaurant?.slug && story?.ig_account) && getRestaurantParticipants(restaurant.slug, story.ig_account).then((res) => {
      setParticipants((res || []).map((p) => ({ label: `@${p}`, value: p })));
    });
  }, [restaurant, story]);

  useEffect(() => {
    gaEvent({ name: "us_thanks" });
  }, []);

  return (
    <div className="USThanks">
      <div className="USThanks__layout">
        {!restaurant.has_referrals && (
          <div className="USThanks__thanks-img">
            <img src={thanks} />
          </div>
        )}

        <div className="USThanks__content">
          <div className="title">
            ¡Disfruta de tu premio!
          </div>
          {restaurant.has_referrals && (<>
            <div className="USThanks__referral">
              <div className="USThanks__referral__title">
                ¿Quieres ganar <span>premios MUCHO más grandes?</span>
              </div>
              <div className="USThanks__referral__subtitle">
                Invita amigos a hacer lo mismo. Cuando ellos terminen, deberán poner tu cuenta de Instagram en esta pantalla.
              </div>
              <div className="USThanks__referral__subtitle">🔥Cuantos más amigos traigas mejores serán los premios🔥
              </div>
            </div>
            {story && !story?.referral && (
              <>
                <div className="USThanks__referral">
                  <div className="USThanks__referral__title">
                    ¿Vienes de parte de alguien?
                  </div>
                  <div className="USThanks__referral__subtitle">Añade aquí su cuenta de Instagram y ayúdale a ganar premios increibles.</div>
                  <div className="USThanks__referral__input">
                    <AutocompleteInput
                      options={participants}
                      placeholder="Cuenta de Instagram"
                      onChange={(v) => { setReferral(v?.value || "") }}
                    />
                  </div>
                </div>
                <div className="USThanks__referral__button">
                  <button
                    className={`button primary ${!referral ? "disabled" : ""}`}
                    disabled={!referral}
                    onClick={handleCreateReferral}
                  >
                    Confirmar
                  </button>
                </div>
              </>
            )}
          </>)}
        </div>
      </div>
    </div>
  );
};

export default USThanks;
