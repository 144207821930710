import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import useStories from "../../../models/stories/useStories";
import { gaEvent } from "../../../utils/gaHelper";
import useCampaigns from "../../../models/campaigns/useCampaigns";
import EmailModal from "./EmailModal";
import useRestaurants from "../../../models/restaurants/useRestaurants";

import "./styles.scss";

const USQR = () => {
  const { storyId } = useParams();
  const navigate = useNavigate();
  const { fetchStory, fetchStoryStatus, story } = useStories();
  const { restaurant } = useRestaurants();
  const { campaign } = useCampaigns();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (story) {
      fetchStoryStatus(storyId);
    }
  }, [story]);

  useEffect(() => {
    fetchStory(storyId);
  }, [storyId]);

  useEffect(() => {
    gaEvent({ name: "us_show_qr" });
  }, []);

  if (story) {
    story.status === "VALIDATED" && navigate(`../thanks/${storyId}`);
    story.status === "REJECTED" && navigate("../error");
  }

  return (
    story && (
      <>
        <div className="USQR">
          <div className="USQR__main">
            <div className="title">¡Gracias!</div>
            <div className="USQR__text">
              Muestra este código QR al camarero para recibir tu premio:{" "}
              <b>{campaign.influencer_prize.toLowerCase()} gratis.</b>
            </div>
            <div className="USQR__qr-wrapper">
              <div className="USQR__qr">
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: "300px",
                    width: "100%",
                  }}
                  value={JSON.stringify(story)}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
          {restaurant.has_delivery && (
            <div className="USQR__delivery">
              <div className="USQR__delivery__title">¿Estás en tu casa?</div>
              <div className="USQR__delivery__text">
                ¡No te preocupes!, te enviamos un email para que puedas canjear
                tu premio.
              </div>
              <button
                className="button secondary"
                onClick={() => setShowModal(true)}
              >
                Enviar por email
              </button>
            </div>
          )}
        </div>
        {showModal && (
          <EmailModal onClose={() => setShowModal(false)} story={story} />
        )}
      </>
    )
  );
};

export default USQR;
