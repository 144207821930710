import useFetch from "../../utils/useFetch";

const useReferrals = () => {
    const { post } = useFetch();
    const endpoint = "referrals";

    const create = async (body) => {
        return await post({
            endpoint: `${endpoint}/create`,
            body,
            isProtected: false,
        });
    };

    return {
        createReferral: create,
    }
}

export default useReferrals;    