import { useSelector, useDispatch } from "react-redux";
import useFetch from "../../utils/useFetch";
import { setDetail, clearDetail } from "./store";

const useRestaurants = () => {
  const { detail } = useSelector((state) => state.restaurants);
  const dispatch = useDispatch();
  const { get } = useFetch();
  const endpoint = "restaurants";

  const fetchDetail = async (slug) => {
    const response = await get({ endpoint: `${endpoint}/${slug}` });
    dispatch(setDetail(response));
  };

  const canUserParticipate = async (slug) => {
    return await get({
      endpoint: `${endpoint}/${slug}/user-participate`,
      isPrivate: true,
    });
  };

  const getRestaurantParticipants = async (slug, igAccount) => {
    return await get({
      endpoint: `${endpoint}/${slug}/participants`,
      params: {
        ig_account: igAccount,
      },
    });
  };

  return {
    restaurant: detail,
    fetchRestaurant: fetchDetail,
    clearRestaurant: () => dispatch(clearDetail()),
    canUserParticipate,
    getRestaurantParticipants,
  };
};

export default useRestaurants;
