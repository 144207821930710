const TermsOfService = () => {
  return (
    <div>
      <h2>Condiciones del Servicio</h2>
      <p>
        Bienvenido a Wubalu, una aplicación desarrollada por Wubalu CORP, SL con
        NIF B19825207. Al acceder y utilizar nuestra aplicación, aceptas cumplir
        con los siguientes términos y condiciones:
      </p>
      <ul>
        <li>
          <strong>Almacenamiento de datos:</strong> Wubalu almacena únicamente
          el nombre de usuario de Instagram proporcionado por el usuario.
        </li>
        <li>
          <strong>Uso de datos:</strong> Los datos recopilados son utilizados
          exclusivamente para el funcionamiento de la aplicación y no son
          compartidos con terceros.
        </li>
        <li>
          <strong>Eliminación de datos:</strong> Los usuarios pueden solicitar
          la eliminación de sus datos enviando un correo electrónico a
          hello@wubalu.com desde la dirección asociada a su cuenta.
        </li>
        <li>
          <strong>Uso adecuado:</strong> Wubalu está destinado únicamente para
          uso personal. No se permite el uso comercial o cualquier actividad que
          viole los términos de servicio de Instagram.
        </li>
        <li>
          <strong>Licencia de uso del contenido:</strong> Al subir contenido
          (incluyendo, sin limitación, imágenes, videos y textos) a la
          plataforma de Wubalu, S.L., usted concede a Wubalu, S.L. y al
          restaurante al que ha subido el contenido una licencia no exclusiva,
          transferible, sublicenciable, libre de regalías y mundial para
          utilizar, modificar, reproducir y distribuir dicho contenido en
          cualquier medio y para cualquier propósito, incluyendo, sin
          limitación, fines publicitarios y promocionales. Usted confirma y
          garantiza que posee todos los derechos necesarios sobre el contenido
          que sube y que la concesión de estos derechos a Wubalu, S.L. y al
          restaurante no infringe los derechos de terceros, incluyendo derechos
          de privacidad y propiedad intelectual. Wubalu, S.L. y el restaurante
          se reservan el derecho de usar el contenido subido de forma indefinida
          y de editarlo según sea necesario para cumplir con sus objetivos
          publicitarios y promocionales.
        </li>
        <li>
          <strong>Cambios en los términos:</strong> Wubalu, SL se reserva el
          derecho de modificar estos términos en cualquier momento. Se
          notificará a los usuarios sobre cualquier cambio significativo.
        </li>
        <li>
          <strong>Contacto:</strong> Para cualquier pregunta o inquietud
          relacionada con nuestros términos de servicio, contáctenos a través de
          hello@wubalu.com.
        </li>
      </ul>
      <p>
        Al utilizar Wubalu, aceptas cumplir con estos términos y condiciones. Si
        no estás de acuerdo con alguno de estos términos, por favor, abstente de
        utilizar nuestra aplicación.
      </p>
    </div>
  );
};

export default TermsOfService;
